import MDCComponent from '@material/base/component';
import {FormConfig} from '.';
import {FieldContainer} from './fieldcontainer';
import {MDCTextField, MDCTextFieldHelperText} from '@material/textfield';
import {MDCSelect, MDCSelectHelperText} from '@material/select';
import {observable} from 'aurelia-binding';
import {PLATFORM} from 'aurelia-pal';

export abstract class Field<T extends MDCComponent<any>> {
	@observable public abstract value: any;
	protected abstract readonly view: string;
	public abstract readonly MDCComponent: {new(...args: any): T};
	public abstract containerCssClasses: string[] = [];

	protected readonly containerModel: string = PLATFORM.moduleName('elements/form/fieldcontainer');
	protected readonly containerView: string = PLATFORM.moduleName('elements/form/fieldcontainer.html');
	protected readonly hasOutline: boolean = false;
	public htmlId: string;
	public form: FormConfig;
	public container: FieldContainer<T>;
	public hasOutsideLabel: boolean = false;
	public gridCellCssClass?: string;
	public index?: number;
	public isHidden: boolean = false;

	public attached?(): void;
	public detached?(): void;
	public valueChanged?(): void;

	constructor(
		public readonly id: string,
		public label?: string,
		public icon?: string
	) {
	}

	public setForm(form?: FormConfig): void {
		this.form = form;
		this.htmlId = `field${form ? `-${form.id}` : ''}-${this.id.replace(/\W+/g, '_')}${typeof this.index === 'number' ? `_${this.index}` : ''}`;
	}

	public setContainer(container: FieldContainer<T>) {
		this.container = container;
	}

	public reset(): void {
		this.value = '';
	}
}

class Helper {
	public helperTextElement: HTMLDivElement;
	public helperTextMdcComponent: MDCTextFieldHelperText | MDCSelectHelperText;
	@observable public text: string;
	private containerCss: string;
	private helperCss: string;

	public set baseClass(baseClass: string) {
		this.containerCss = baseClass + '-helper-line';
		this.helperCss = `${baseClass}-helper-text ${baseClass}-helper-text--validation-msg ${baseClass}-helper-text--persistent`;
	}

	public setMdcComponent(HelperText: typeof MDCTextFieldHelperText | typeof MDCSelectHelperText): void {
		this.helperTextMdcComponent = new HelperText(this.helperTextElement);
	}

	public detached(): void {
		this.helperTextMdcComponent.destroy();
	}
}

export abstract class ValidatingField<T extends MDCTextField | MDCSelect> extends Field<T> {
	protected error: string = '';
	protected isValid: boolean = true;
	public helper: Helper = new Helper();

	public abstract attached(): void;

	constructor(
		id: string,
		label?: string,
		icon?: string,
		public isRequired: boolean = false
	) {
		super(id, label, icon);
	}

	public detached(): void {
		this.helper.detached();
	}

	public setContainer(container: FieldContainer<T>): void {
		super.setContainer(container);
		this.helper.helperTextElement = this.container.helperTextElement;
		this.helper.baseClass = this.containerCssClasses[0];
	}

	public validate(): boolean {
		this.flag(this.isRequired && !this.value && 'Required');
		return this.isValid;
	}

	public revalidate(): boolean {
		return this.isValid || this.validate();
	}

	public flag(error?: string|false): void {
		this.error = error || '';
		this.isValid = !error;

		const component = this.container && this.container.mdcComponent;
		if (component) component.valid = this.isValid;
	}
}
