import {Api} from "api";
import {Route} from "route";
import {NavigationInstruction} from "aurelia-router";
import {UserData} from 'modelData';
import {Aurelia} from 'aurelia-framework';

export interface AppConfigData {
	title: string;
	env: string;
	user?: UserData;
}

export abstract class AppConfig implements AppConfigData {
	public static currentConfig: AppConfigData;

	public title: string;
	public env: string;
	public user: UserData = null;

	public navIsOpen: boolean = false;
	public signinRedirect?: NavigationInstruction;
	public abstract readonly perspective: string;
	public abstract readonly routes: Route[];

	constructor(config: AppConfigData) {
		Object.assign(this, config);
	}

	public toggleNav(): void {
		this.navIsOpen = !this.navIsOpen;
	}

	public static load(): Promise<AppConfigData | void> {
		return Api.get<AppConfigData>('config').then((response) => this.currentConfig = response.data, (e) => {
			console.error('FATAL: Could not load config');
		});
	}
}
