import {autoinject, PLATFORM, viewResources} from 'aurelia-framework';
import {NavModel, RouterEvent} from 'aurelia-router';
import {EventAggregator} from 'aurelia-event-aggregator';
import {MDCTopAppBar, numbers} from '@material/top-app-bar';
import {App} from 'app';
import {Route} from 'route';

import './appbar.scss';

// Override max appbar height
numbers.MAX_TOP_APP_BAR_HEIGHT = 200;

@autoinject
@viewResources(PLATFORM.moduleName('elements/tabbar/tab'))
export class Header {
	private navigation: NavModel[];
	private mdcComponent: MDCTopAppBar;
	private css: string;
	private adjustCss: string;
	private resizeTimeout: number;

	constructor(public readonly app: App, private readonly element: Element, eventAggregator: EventAggregator) {
		app.load.then(() => {
			this.refresh();
			eventAggregator.subscribe('auth', () => {
				this.refresh();
			});
			eventAggregator.subscribe(RouterEvent.Complete, () => {
				this.refreshCss();
			});
			window.addEventListener('resize', () => {
				if (this.resizeTimeout) window.clearTimeout(this.resizeTimeout);
				this.resizeTimeout = window.setTimeout(() => {
					this.resizeTimeout = null;
					if (window.pageYOffset < this.element.clientHeight) this.refreshCss(true);
				}, 200);
			});
		});
	}

	private refresh(): void {
		const {user} = this.app.config;
		this.navigation = this.app.router.navigation.filter((navModel) => {
			const {route}: {route: Route} = navModel.settings;
			if (!route.isVisible) return false;
			if (route.isPublic) return !route.isPublicOnly || !user;
			return !!user;
		});
	}

	private refreshCss(forceReattach?: boolean): void {
		const isGuest = !this.app.config.user;
		const isHome = this.app.currentRoute.name === 'home';
		const isMobile = window.innerWidth < 730;
		let css = 'mdc-top-app-bar';
		if (!isMobile) css += (isHome && isGuest ? ' mdc-top-app-bar--prominent' : ' mdc-top-app-bar--fixed');
		if (this.app.currentRoute.hasDrawer) css += ' has-drawer';
		if (this.app.config.user) css += ' signed-in';
		if (forceReattach || css !== this.css) {
			this.css = css;
			this.adjustCss = isHome && isGuest && !isMobile ? 'mdc-top-app-bar--prominent-fixed-adjust' : 'mdc-top-app-bar--fixed-adjust';
			this.element.className = this.css;
			this.detached();
			this.attachComponent();
		}
	}

	private attachComponent(): void {
		this.mdcComponent = new MDCTopAppBar(this.element);
	}

	private attached(): void {
		this.refreshCss();
	}

	private detached(): void {
		if (this.mdcComponent) this.mdcComponent.destroy();
	}

	public signIn(): void {
		this.app.dialogContainer.open(PLATFORM.moduleName('dialogs/signin'));
	}

	public toggleDrawer(): void {
		this.app.eventAggregator.publish('toggleDrawer');
	}

	public get height(): number {
		return this.element && this.element.clientHeight || 0;
	}
}


