import {PLATFORM} from 'aurelia-pal';
import {AppConfig} from 'appconfig';
import {Route, PublicRoute} from 'route';

export class ReviewerAppConfig extends AppConfig {
	public readonly perspective = 'reviewer';
	public readonly routes = [
		new Route(PLATFORM.moduleName('perspectives/reviewer/views/home', 'perspective.reviewer'), '', null, false, null, ''),
		new Route(PLATFORM.moduleName('perspectives/reviewer/views/users', 'perspective.reviewer'), 'Applicants', 'people', true),
		new Route(PLATFORM.moduleName('perspectives/reviewer/views/user', 'perspective.reviewer'), 'Applicant Details', null, false, '/:userId', 'users'),
		new Route(PLATFORM.moduleName('perspectives/reviewer/views/review', 'perspective.reviewer'), 'Review Document', null, false, '/:userId/documents/:documentId/:sectionId?', 'users', true),
		new Route(PLATFORM.moduleName('perspectives/reviewer/views/account', 'perspective.reviewer'), 'My Account', 'account_circle', true),
		new Route(PLATFORM.moduleName('views/signout'), 'Sign Out', 'exit_to_app', true),
		new PublicRoute(PLATFORM.moduleName('views/404/index.html'), '404 - Page not found', null, false, null, '404')
	];
}
