import {bindable} from 'aurelia-framework';

import './button.scss';

export class Button {
	@bindable private readonly icon: string;
	@bindable private readonly isDefault: boolean = false;
	@bindable private readonly type: string = 'button';
	private css: string;
	private defaultAttr?: string;

	private isDefaultChanged(): void {
		this.css = this.isDefault ? 'mdc-button--unelevated' : '';
		this.defaultAttr = this.isDefault ? '' : undefined;
	}
}
