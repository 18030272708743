import {observable} from 'aurelia-binding';
import {MDCSnackbar} from '@material/snackbar';

export class Snackbar {
	@observable private element: HTMLDivElement;
	public mdcComponent: MDCSnackbar;
	public remove: () => void;
	private isRemoved: boolean = false;

	constructor(
		private readonly snackbars: Set<Snackbar>,
		private readonly message: string,
		private readonly timeout?: number,
		private readonly buttonText?: string,
		public readonly buttonAction: () => void = () => {},
		private readonly isHtml: boolean = false
	) {}

	private elementChanged(): void {
		if (!this.element) return;
		this.mdcComponent = new MDCSnackbar(this.element);
		if (this.timeout !== undefined) this.mdcComponent.timeoutMs = this.timeout;
		this.mdcComponent.open();

		this.remove = () => {
			if (this.isRemoved) return;
			this.isRemoved = true;
			this.snackbars.delete(this);
			this.mdcComponent.unlisten('MDCSnackbar:closed', this.remove);
			this.mdcComponent.destroy();
		};
		this.mdcComponent.listen('MDCSnackbar:closed', this.remove);
	}
}
