import {HttpClient, HttpResponseMessage, RequestBuilder} from 'aurelia-http-client';
import {ModelData} from 'modelData';
import {AppConfigData} from 'appconfig';

type ResponseData = ModelData | ModelData[] | AppConfigData;

export interface ApiResponse<T extends ResponseData = ModelData> {
	success: boolean;
	error?: any;
	data?: T;
	[index: string]: any;
}

export type ApiMethod = 'get' | 'put' | 'post' | 'delete';

export interface ApiCredentials {
	email: string;
	password: string;
}

export class Api {
	private static client = new HttpClient().configure((builder) => {
		builder.withBaseUrl('/api');
	});
	private static request(uri: string, credentials?: ApiCredentials): RequestBuilder {
		const builder = this.client.createRequest(uri);
		if (!credentials) return builder;
		return builder.withLogin(credentials.email, credentials.password);
	}
	private static respond<T extends ResponseData = ModelData>(response: HttpResponseMessage): Promise<ApiResponse<T>> {
		const content: ApiResponse<T> = response.content;
		return new Promise((resolve, reject) => {
			if (!response.isSuccess || !content || !content.success) return reject(content);
			resolve(content);
		});
	}
	public static get<T extends ResponseData = ModelData>(uri: string, params?: {[index: string]: any}, auth?: ApiCredentials): Promise<ApiResponse<T>> {
		return this.request(uri, auth).asGet().withParams(params).send().then((response) => this.respond<T>(response), (response) => this.respond<T>(response));
	}
	public static put<T extends ResponseData = ModelData>(uri: string, params?: T, auth?: ApiCredentials): Promise<ApiResponse<T>> {
		return this.request(uri, auth).asPut().withHeader('Content-type', 'application/json').withContent(JSON.stringify(params)).send().then((response) => this.respond<T>(response), (response) => this.respond<T>(response));
	}
	public static post<T extends ResponseData = ModelData>(uri: string, params?: T, auth?: ApiCredentials): Promise<ApiResponse<T>> {
		return this.request(uri, auth).asPost().withHeader('Content-type', 'application/json').withContent(JSON.stringify(params)).send().then((response) => this.respond<T>(response), (response) => this.respond<T>(response));
	}
	public static delete<T extends ResponseData = ModelData>(uri: string, params?: {[index: string]: any}, auth?: ApiCredentials): Promise<ApiResponse<T>> {
		return this.request(uri, auth).asDelete().withParams(params).send().then((response) => this.respond<T>(response), (response) => this.respond<T>(response));
	}
	public static upload(uri: string, data: FormData, onProgress: (ProgressEvent) => void): Promise<ApiResponse> {
		return this.client.createRequest(uri + '/upload').asPost().withProgressCallback(onProgress).withContent(data).send().then(this.respond, this.respond);
	}
}
