import {ApiFormConfig} from 'elements/form';
import {EmailField, PasswordField} from 'elements/form/input';
import {CheckboxField} from 'elements/form/choice';
import {CancelButton, OkButton, DialogButton} from 'elements/dialog/button';
import {Dialog} from 'elements/dialog';
import {ApiResponse} from 'api';
import {UserData} from 'modelData';
import {PLATFORM} from 'aurelia-pal';

import './signin.scss';

class SignInForm extends ApiFormConfig<Dialog> {
	public readonly service = 'session';
	public readonly method = 'post';

	public onSuccess(response: ApiResponse, dialog: Dialog): void {
		const {app} = this.viewModel;
		const {signinRedirect} = app.config;
		const user: UserData = response.data;

		dialog.closeAll();
		app.updateAuth(user);
		app.cookieNotice.accept();
		if (app.config.perspective === 'client') {
			if (signinRedirect && signinRedirect.config.name && signinRedirect.config.name !== 'signin') {
				app.router.navigateToRoute(signinRedirect.config.name, signinRedirect.params);
			} else if (user.documents.length === 1) {
				app.router.navigateToRoute('document', {documentId: user.documents[0]});
			} else {
				app.router.navigateToRoute('documents');
			}
		} else {
			if (signinRedirect && signinRedirect.config.name && signinRedirect.config.name !== 'signin') {
				app.router.navigate(signinRedirect.fragment, {trigger: false});
			} else {
				app.router.navigate('', {trigger: false});
			}
			location.reload();
		}
	}
}

export class SignIn extends Dialog {
	protected readonly heading: string = 'Sign In';
	protected readonly icon: string = 'account_circle';
	protected cssClass: string = 'sign-in';
	public readonly emailField: EmailField = new EmailField(true);

	private form: SignInForm = new SignInForm(
		'sign-in',
		this.emailField,
		new PasswordField(true),
		new CheckboxField('persist', 'Keep me signed in')
	);

	protected readonly buttons = [
		new DialogButton('help', 'Forgot Password', () => {
			this.app.dialogContainer.open(PLATFORM.moduleName('dialogs/forgotpassword'));
		}, 'left'),
		new CancelButton(() => {
			this.close();
		}),
		new OkButton(() => {
			this.form.submit(this);
		}, 'Sign In')
	];

	protected attached(): void {
		this.onOpened = () => {
			window.dispatchEvent(new Event('resize'));
		};
		super.attached();
	}
}
