import {App} from 'app';
import {autoinject} from 'aurelia-framework';

@autoinject
export class ViewModel {
	constructor(public readonly app: App) {
		this.app.isLoading = true;
	}

	protected attached(): void {
		this.app.isLoading = false;
	}
}
