import {autoinject, bindable} from 'aurelia-framework';
import {MDCTab} from '@material/tab';

import './tab.scss';

@autoinject
export class Tab {
	@bindable private icon: String;
	@bindable private isActive: Boolean;
	private mdcComponent: MDCTab;

	constructor(private element: Element) {}

	attached() {
		this.mdcComponent = new MDCTab(this.element);
	}

	detached() {
		this.mdcComponent.destroy();
	}
}
