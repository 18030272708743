import {PLATFORM} from 'aurelia-pal';
import {Field} from 'elements/form/field';
import {MDCFormField} from '@material/form-field';
import {MDCRadio} from '@material/radio';
import {MDCCheckbox} from '@material/checkbox';
import {observable} from 'aurelia-framework';
import {FormConfig} from '.';
import {InputField} from 'elements/form/input';

import './choice.scss';

export class Choice {
	public choice: ChoiceField;

	private detached(): void {
		this.choice.detached();
	}

	private activate(field: ChoiceField): void {
		this.choice = field;
	}
}

export abstract class ChoiceField extends Field<null> {
	protected abstract readonly fields: OptionField<any>[];
	protected readonly containerModel: string = PLATFORM.moduleName('elements/form/choice');
	protected readonly containerView: string = PLATFORM.moduleName('elements/form/choice.html');
	protected readonly view = null;
	private readonly isChoice: boolean = true;
	public readonly MDCComponent;
	public readonly containerCssClasses;

	public setForm(form: FormConfig) {
		super.setForm(form);
		for (const field of this.fields) {
			field.parent = this;
			field.setForm(form);
		}
		if (this.valueChanged) this.valueChanged();
	}

	public detached(): void {
		for (const field of this.fields) field.detached();
	}
}

export class SingleChoiceField extends ChoiceField {
	protected readonly fields: RadioField[];
	@observable public selectedValue: string = '';
	private readonly otherField: OtherRadioField;

	constructor(id: string, label: string, options: string[], allowOther: boolean = false) {
		super(id, label);
		this.fields = options.map((label, i) => new RadioField(`${id}_${i}`, label));
		if (allowOther) this.fields.push(this.otherField = new OtherRadioField(id));
	}

	public get value(): string {
		return this.otherField && this.selectedValue === 'Other' ? this.otherField.value : this.selectedValue;
	}

	public set value(value: string) {
		if (value && this.otherField && !this.fields.find((field) => field.label === value)) {
			this.selectedValue = 'Other';
			this.otherField.value = value;
		} else {
			this.selectedValue = value;
		}
	}

	public selectedValueChanged(): void {
		if (this.valueChanged) this.valueChanged();
		if (this.otherField) {
			this.otherField.showOtherField = this.selectedValue === 'Other';
			if (this.otherField.showOtherField && this.otherField.inputField.container) setTimeout(() => {
				this.otherField.inputField.container.mdcComponent.focus();
			}, 0);
		}
	}
}

export class MultipleChoiceField extends ChoiceField {
	protected readonly fields: CheckboxField[];
	private readonly otherField: OtherCheckboxField;

	constructor(id: string, label: string, options: string[], private readonly exclusiveOptions?: string[], allowOther: boolean = false) {
		super(id, label);
		this.fields = options.map((label, i) => new CheckboxField(`${id}_${i}`, label));
		if (allowOther) this.fields.push(this.otherField = new OtherCheckboxField(id));
	}

	public get value(): string[] {
		const value = this.fields
			.filter((field) => field.value && (!(field instanceof OtherCheckboxField) || field.inputField.value))
			.map((field) => field instanceof OtherCheckboxField ? field.inputField.value : field.label);
		return value.length ? value : null;
	}

	public set value(rawValues: string[]) {
		const values = new Set(rawValues);
		for (const field of this.fields) {
			field.value = values.delete(field.label);
		}
		if (values.size) {
			this.otherField.value = true;
			this.otherField.inputField.value = Array.from(values)[0];
		}
	}

	public boxChanged(field: CheckboxField): void {
		if (!this.exclusiveOptions || !this.exclusiveOptions.length) return;
		if (!field.value) return;
		for (const sibling of this.fields) {
			if (sibling === field) continue;
			if (this.exclusiveOptions.includes(field.label) || this.exclusiveOptions.includes(sibling.label)) sibling.value = false;
		}
	}
}

abstract class OptionField<T extends ChoiceField> extends Field<MDCFormField> {
	protected readonly containerModel = PLATFORM.moduleName('elements/form/fieldmodel');
	protected readonly hasOutline = false;
	protected readonly element: HTMLDivElement;
	protected readonly inputElement: HTMLDivElement;
	protected readonly view;
	protected outerMdcComponent: MDCFormField;
	protected innerMdcComponent: MDCRadio|MDCCheckbox;
	public readonly MDCComponent;
	public readonly containerCssClasses;
	public parent: T;
	public value;

	public attached(): void {
		this.outerMdcComponent = new MDCFormField(this.element);
	}

	public detached(): void {
		if (this.innerMdcComponent) this.innerMdcComponent.destroy();
		if (this.outerMdcComponent) this.outerMdcComponent.destroy();
	}
}

class RadioField extends OptionField<SingleChoiceField> {
	protected readonly containerView = PLATFORM.moduleName('elements/form/radio.html');

	public attached(): void {
		super.attached();
		this.innerMdcComponent = new MDCRadio(this.inputElement);
	}
}

class OtherRadioField extends RadioField {
	private readonly isOther: boolean = true;
	public readonly inputField: InputField;
	public showOtherField: boolean = false;

	constructor(id: string) {
		super(`${id}_other`, 'Other');
		this.inputField = new InputField('other');
		this.inputField.placeholder = 'Other';
		this.inputField.setForm();
		this.inputField.valueChanged = () => {
			if (this.parent && this.parent.valueChanged) this.parent.valueChanged();
		};
	}

	public get value(): string {
		return this.inputField.value;
	}

	public set value(value: string) {
		this.inputField.value = value;
	}
}

export class CheckboxField extends OptionField<MultipleChoiceField> {
	@observable public value: boolean = false;
	protected readonly containerView = PLATFORM.moduleName('elements/form/checkbox.html');

	public attached(): void {
		super.attached();
		this.innerMdcComponent = new MDCCheckbox(this.inputElement);
	}

	public valueChanged(): void {
		if (this.parent) this.parent.boxChanged(this);
		if (this.parent && this.parent.valueChanged) this.parent.valueChanged();
	}
}

class OtherCheckboxField extends CheckboxField {
	private readonly isOther: boolean = true;
	public readonly inputField: InputField;
	public showOtherField: boolean = false;

	constructor(id: string) {
		super(`${id}_other`, 'Other');
		this.inputField = new InputField('other');
		this.inputField.placeholder = 'Other';
		this.inputField.setForm();
		this.inputField.valueChanged = () => {
			this.valueChanged();
		};
	}

	public valueChanged(): void {
		super.valueChanged();
		this.showOtherField = this.value;
		if (this.showOtherField && this.inputField.container) setTimeout(() => {
			this.inputField.container.mdcComponent.focus();
		}, 0);
	}
}
