import {viewResources, PLATFORM} from 'aurelia-framework';
import {Snackbar} from './snackbar';

import './snackbar.scss';
import '../dialog/dialog.scss';

@viewResources(
	PLATFORM.moduleName('elements/button'),
	PLATFORM.moduleName('elements/button/iconbutton')
)
export class SnackbarContainer {
	private readonly snackbars: Set<Snackbar> = new Set();

	public open(message: string, timeout?: number, buttonText?: string, buttonAction?: () => void, isHtml?: boolean): Snackbar {
		const snackbar = new Snackbar(this.snackbars, message, timeout, buttonText, buttonAction, isHtml);
		if (this.snackbars.size >= 3) this.snackbars.values().next().value.remove();
		this.snackbars.add(snackbar);
		return snackbar;
	}
}
