import {RouterConfiguration, Router, NavigationInstruction, Next, Redirect} from 'aurelia-router';
import {EventAggregator} from 'aurelia-event-aggregator';
import {AppConfig, AppConfigData} from 'appconfig';
import {CookieNotice} from 'cookienotice';
import {ClientAppConfig} from 'perspectives/client/appconfig';
import {ReviewerAppConfig} from 'perspectives/reviewer/appconfig';
import {AdminAppConfig} from 'perspectives/admin/appconfig';
import {viewResources, PLATFORM, autoinject} from 'aurelia-framework';
import {DialogContainer} from 'elements/dialog/container';
import {SnackbarContainer} from 'elements/snackbar/container';
import {Route} from 'route';
import {UserData} from 'modelData';
import {Header} from 'elements/header';

import './app.scss';

@autoinject
@viewResources(
	PLATFORM.moduleName('elements/header'),
	PLATFORM.moduleName('elements/footer'),
	PLATFORM.moduleName('elements/dialog/container'),
	PLATFORM.moduleName('elements/snackbar/container')
)
export class App {
	private readonly configure: Promise<void>;
	public routerConfig: RouterConfiguration;
	public readonly dialogContainer: DialogContainer;
	public readonly snackbarContainer: SnackbarContainer;
	public readonly cookieNotice: CookieNotice;
	public readonly header: Header;
	public readonly load: Promise<Router>;
	public isLoading: boolean = false;
	public router: Router;
	public config: AppConfig;
	public currentRoute: Route;

	private loaded: () => void;

	constructor(public readonly eventAggregator: EventAggregator) {
		this.load = new Promise((resolve) => {
			this.loaded = resolve;
		});
		this.updateAuth(AppConfig.currentConfig.user);
		this.cookieNotice = new CookieNotice(this);
	}

	private configureRouter(routerConfig: RouterConfiguration, router: Router): void {
		const app = this;
		this.router = router;
		this.routerConfig = routerConfig;
		routerConfig.titleSeparator = ' // ';
		routerConfig.title = this.config.title;
		routerConfig.options.pushState = true;
		routerConfig.options.root = '/';
		routerConfig.addAuthorizeStep({
			run(navInstruction: NavigationInstruction, next: Next): Promise<any> {
				const route: Route = navInstruction.config.settings.route;
				if (!app.config.user && !route.isPublic) {
					app.config.signinRedirect = navInstruction;
					app.load.then(() => app.header.signIn());
					return next.cancel(new Redirect(''));
				}
				return next();
			}
		});
		routerConfig.addPreRenderStep({
			run(navInstruction: NavigationInstruction, next: Next): Promise<any> {
				app.config.navIsOpen = false;
				app.currentRoute = navInstruction.config.settings.route;
				if (navInstruction.router.isNavigatingNew) window.scroll(0, 0);
				return next();
			}
		});
		routerConfig.map(app.config.routes.map((route: Route) => route.config));
		routerConfig.mapUnknownRoutes(app.config.routes.find((route) => route.name === '404').config);
	}

	private attached(): void {
		this.loaded();
		this.cookieNotice.show();
	}

	private getConfig(configData: AppConfigData): AppConfig {
		switch (configData.user && configData.user.type) {
			case 'admin': return new AdminAppConfig(configData);
			case 'reviewer': return new ReviewerAppConfig(configData);
			default: return new ClientAppConfig(configData);
		}
	}

	public updateAuth(user?: UserData): void {
		const configData = AppConfig.currentConfig;
		configData.user = user || null;
		this.config = this.getConfig(configData);
		this.eventAggregator.publish('auth', user);
	}
}
