import {PLATFORM} from 'aurelia-framework';
import {ValidatingField} from 'elements/form/field';
import {MDCSelect, MDCSelectHelperText, MDCSelectFoundation} from '@material/select';

import './select.scss';
import '../menu/menu.scss';
import '../list/list.scss';

export class SelectField extends ValidatingField<MDCAdvancedSelect> {
	protected readonly containerView: string = PLATFORM.moduleName('elements/form/selectcontainer.html');
	protected readonly view = PLATFORM.moduleName('elements/form/select.html');
	protected readonly hasOutline = true;
	protected readonly hasSearch: boolean = false;
	protected readonly options: SelectOption[];
	protected readonly menuElement: HTMLDivElement;
	private readonly isSelect: boolean = true;
	private initialValue: string = '';
	public readonly MDCComponent = MDCAdvancedSelect;
	public containerCssClasses = ['mdc-select', 'mdc-select--outlined'];

	private onChanged: () => void;

	constructor(id: string, label: string, options: (SelectOption | string)[], icon?: string, isRequired?: boolean) {
		super(id, label, icon, isRequired);
		if (this.isRequired) this.label += '*';
		this.options = [];
		this.addOptions(...options);
	}

	private setWidth(): void {
		if (!this.container.element || !this.container.fieldElement) return;
		this.container.fieldElement.style.width = this.container.element.clientWidth + 'px';
	}

	protected updateMenuPosition(): void {
		Object.assign(this.menuElement.style, {
			top: (this.container.element.offsetTop - window.scrollY) + 'px'
		});
	}

	public addOptions(...options: (SelectOption | string)[]): void {
		this.options.push(...options.map((option) => option instanceof SelectOption ? option : new SelectOption(option)));
	}

	public attached(): void {
		if (this.initialValue) {
			this.container.mdcComponent.value = this.initialValue;
			this.initialValue = '';
		}
		this.onChanged = () => {
			this.revalidate();
			if (this.valueChanged) this.valueChanged();
		};
		this.container.mdcComponent.menuOpened = () => {
			this.updateMenuPosition();
		};
		this.container.mdcComponent.listen('MDCSelect:change', this.onChanged);
		this.helper.setMdcComponent(MDCSelectHelperText);
		this.setWidth();
	}

	public detached(): void {
		this.container.mdcComponent.unlisten('MDCSelect:change', this.onChanged);
	}

	public get value(): string {
		return this.container && this.container.mdcComponent && this.container.mdcComponent.value || this.initialValue;
	}

	public set value(value: string) {
		if (this.container && this.container.mdcComponent) {
			this.container.mdcComponent.value = value;
		} else {
			this.initialValue = value;
		}
	}
}

export class ComboField extends SelectField {
	protected readonly hasSearch: boolean = true;
	private readonly searchElement?: HTMLInputElement;
	
	constructor(id: string, label: string, options: (SelectOption | string)[], icon?: string, isRequired?: boolean) {
		super(id, label, options, icon, isRequired);
		this.containerCssClasses.push('has-search');
	}

	public attached(): void {
		super.attached();
		this.container.mdcComponent.menuOpened = () => {
			this.searchElement.value = '';
			this.search();
			this.searchElement.focus();
			this.updateMenuPosition();
		};
		this.container.mdcComponent.menuClosed = () => {
			this.searchElement.value = '';
		};
	}

	private keydown(event?: KeyboardEvent): boolean {
		if (event && event.key === 'Enter') {
			event.stopPropagation();
			return false;
		}
		if (event && (event.key === 'Tab' || event.key === 'Escape')) this.container.mdcComponent.close();
		return true;
	}

	private search(event?: KeyboardEvent): boolean {
		if (event) {
			let up: boolean = false;
			switch (event.key) {
				case 'ArrowUp':
					up = true;
					// Continue
				case 'ArrowDown':
				case 'Enter':
					let next = up ? this.options.length - 1 : 0;
					while (this.options[next] && this.options[next].isHidden) next += (up ? -1 : 1);
					if (this.options[next]) this.container.mdcComponent.focusItem(next);
					break;
			}
		}
		const value = this.searchElement.value.toLocaleLowerCase();
		for (const option of this.options) {
			option.isHidden = !!value && option.localeLabel.substring(0, value.length) !== value;
		}
		return true;
	}
}

export class SelectOption {
	public isHidden: boolean = false;
	public readonly localeLabel: string;

	constructor(public readonly value: string, public readonly label: string = value) {
		this.localeLabel = label.toLocaleLowerCase();
	}
}

class MDCAdvancedSelect extends MDCSelect {
	protected foundation_: MDCAdvancedSelectFoundation;
	protected getFoundationMap_: () => any;

	public getDefaultFoundation(): MDCAdvancedSelectFoundation {
		return new MDCAdvancedSelectFoundation((super.getDefaultFoundation() as any).adapter_);
	}

	public focusItem(index: number): void {
		this.foundation_.focusItem(index);
	}

	public close(): void {
		this.foundation_.closeMenu();
	}

	public set menuOpened(handler: () => void) {
		this.foundation_._menuOpened = handler;
	}

	public set menuClosed(handler: () => void) {
		this.foundation_._menuClosed = handler;
	}
}

class MDCAdvancedSelectFoundation extends MDCSelectFoundation {
	public _menuOpened: () => void;
	public _menuClosed: () => void;

	public handleFocus(): void {
		super.handleFocus();
		super.handleClick(0);
	}

	public handleMenuOpened(): void {
		super.handleMenuOpened();
		if (this._menuOpened) this._menuOpened();
	}

	public handleMenuClosed(): void {
		if (this._menuClosed) this._menuClosed();
		super.handleMenuClosed();
	}

	public focusItem(index: number): void {
		this.adapter_.focusMenuItemAtIndex(index);
	}

	public closeMenu(): void {
		this.adapter_.closeMenu();
	}
}
