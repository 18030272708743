import {viewResources, PLATFORM} from 'aurelia-framework';
import {Dialog} from 'elements/dialog';

import './dialog.scss';

@viewResources(PLATFORM.moduleName('elements/dialog'))
export class DialogContainer {
	private isActive: boolean = false;
	public stack: ActiveDialog[] = [];

	public open(
		bodyView: string,
		data?: any
	): void {
		if (this.last()) this.last().dialog.hide();
		this.stack.push(new ActiveDialog(this, bodyView, data));
		this.isActive = true;
	}

	public close(closeAll?: boolean): void {
		if (this.stack.length && !closeAll) {
			this.stack.pop();
			if (this.stack.length) {
				this.last().dialog.open();
			} else {
				this.isActive = false;
			}
		} else {
			this.isActive = false;
			this.stack = [];
		}
	}

	public last(includeActive: boolean = true): ActiveDialog {
		return this.stack.length && this.stack[this.stack.length - (includeActive ? 1 : 2)];
	}
}

export class ActiveDialog {
	public dialog: Dialog

	constructor(public readonly container: DialogContainer, public readonly view: string, public readonly data?: any) {}
}
