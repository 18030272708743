import {ViewModel} from 'viewModel';
import {viewResources, PLATFORM} from 'aurelia-framework';
import {ApiFormConfig} from 'elements/form';
import {CreatePasswordField, ConfirmPasswordField} from 'elements/form/input';
import {Api, ApiResponse} from 'api';

class ResetForm extends ApiFormConfig {
	protected readonly service = 'user/self/resetpassword';
	protected readonly method = 'put';
	public readonly submitButton = 'Save';
	public key: string;

	protected sendRequest(): Promise<ApiResponse> {
		return this.apiRequest({password: this.values.password, key: this.key});
	}

	protected onSuccess(): void {
		const {app} = this.viewModel;
		app.router.navigateToRoute('home');
		app.snackbarContainer.open('Pasword has been updated');
	}
}

@viewResources(
	PLATFORM.moduleName('elements/form')
)
export class ResetPassword extends ViewModel {
	private form: ResetForm;
	
	protected attached(): void {
		const {key} = this.app.router.currentInstruction.params;

		Api.get('user/self/resetpassword', {key}).then((response) => {
			this.form = new ResetForm(
				'reset-password',
				new CreatePasswordField(true),
				new ConfirmPasswordField(true)
			);
			this.form.key = key;
			super.attached();
		}, (response) => {
			this.app.router.navigateToRoute('home');
			this.app.snackbarContainer.open(response.error);
		});
	}
}
