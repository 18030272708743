import {bindable, viewResources, PLATFORM, autoinject} from 'aurelia-framework';
import {ModelData} from 'modelData';
import {ApiMethod, Api, ApiResponse, ApiCredentials} from 'api';
import {Field, ValidatingField} from './field';
import {App} from 'app';
import {getProperty, encodeEntities} from 'utils';

import './form.scss';

@autoinject
@viewResources(
	PLATFORM.moduleName('elements/form/subsection'),
	PLATFORM.moduleName('elements/button'),
	PLATFORM.moduleName('elements/button/floatingbutton'),
	PLATFORM.moduleName('elements/videoplayer')
)
export class Form {
	@bindable public readonly form: FormConfig;

	constructor(public readonly app: App) {}

	private attached(): void {
		this.form.viewModel = this;
	}
}

export class FormSection {
	public readonly fields: Field<any>[];
	public form: FormConfig;
	public isVisible: boolean = true;
	public isExistingSub: boolean = false;
	public id: string;
	public errorMessage?: string;
	public image?: string;
	public video?: string;

	constructor(
		public readonly heading?: string,
		public readonly subheading?: string,
		...fields: Field<any>[]
	) {
		this.fields = fields;
		if (this.subheading && this.subheading.trim().charAt(0) !== '<') this.subheading = `<p>${encodeEntities(this.subheading)}</p>`;
	}

	public setForm(form: FormConfig): void {
		this.form = form;
		for (const field of this.fields) {
			field.setForm(form);
			form.fields.add(field);
		}
		if (this.heading) {
			const baseId = `formSection-${form.id}-` + this.heading.replace(/\W+/g, ' ').trim().toLowerCase().replace(/ (\w)/g, (m, c) => c.toUpperCase());
			let id = baseId;
			let ct = 0;
			while (form.sections.find((section) => section.id === id)) {
				ct++;
				id = baseId + ct;
			}
			this.id = id;
		}
	}
}

export class FormMultiSection extends FormSection {
	public readonly subSections: Set<FormSection> = new Set();
	private readonly fieldGenerators: ((data?: any) => Field<any>)[];
	private subCount: number = 0;

	constructor(
		public readonly id: string,
		heading?: string,
		subheading?: string,
		public readonly subSectionHeading?: string,
		...fieldGenerators: (() => Field<any>)[]
	) {
		super(heading, subheading);
		this.fieldGenerators = fieldGenerators;
	}

	public setForm(form: FormConfig): void {
		this.form = form;
		for (const section of this.subSections) {
			section.isExistingSub = true;
			section.setForm(form);
		}
	}

	public addSubSection(generatorData?: any): FormSection {
		const section = new FormSection(this.subSectionHeading, undefined, ...this.fieldGenerators.map((generate) => {
			const field = generate(generatorData);
			field.index = this.subCount;
			return field;
		}));
		if (this.form) section.setForm(this.form);
		this.subSections.add(section);
		this.subCount++;
		return section;
	}

	public removeSubSection(section: FormSection): void {
		this.subSections.delete(section);
		for (const field of section.fields) this.form.fields.delete(field);
	}
}

export abstract class FormConfig {
	protected readonly method: ApiMethod = 'post';
	public readonly fields: Set<Field<any>> = new Set();
	public readonly sections: FormSection[] = [];
	public readonly submitButton?: string;
	public readonly hasFloatingSaveButton: boolean = false;
	public viewModel: Form;
	public defaultErrorMessage: string = 'Please correct the indicated errors and try again.';
	public isValid: boolean = true;
	public isLoading: boolean = false;
	protected isProcessing: boolean = false;
	protected errorMessage: string;

	public abstract submit(this: FormConfig, data?: any): void;

	constructor(public readonly id: string, ...fields: FormSection[] | Field<any>[]) {
		if (fields[0] instanceof Field) fields = [new FormSection(null, null, ...fields as Field<any>[])];
		for (const section of fields as FormSection[]) {
			section.setForm(this);
			this.sections.push(section);
		}
	}

	public validate(): boolean {
		this.isValid = true;
		this.errorMessage = this.defaultErrorMessage;
		for (const field of this.fields) {
			if (field instanceof ValidatingField && !field.validate()) this.isValid = false;
		}
		this.showErrors();
		return this.isValid;
	}

	public showErrors(force?: boolean): void {
		if (!this.isValid || force) this.viewModel.app.snackbarContainer.open(this.errorMessage);
	}

	public reset(): void {
		for (const field of this.fields) field.reset();
	}

	public set values(values: ModelData) {
		for (const field of this.fields) {
			const value = getProperty(values, field.id);
			if (value) field.value = value;
		}
	}

	public get values(): ModelData {
		const values = {};
		function set(rawPath: string|string[], obj: any, value: any) {
			const path = typeof rawPath === 'string' ? rawPath.split('.') : rawPath;
			const prop = path.pop();
			const parent = path.reduce((parent, segment) => {
				if (!parent[segment]) parent[segment] = {};
				return parent[segment];
			}, obj);
			parent[prop] = value;
		}
		for (const section of this.sections) {
			if (section instanceof FormMultiSection) {
				set(section.id, values, Array.from(section.subSections).map((sub) => {
					const obj = {};
					for (const field of sub.fields) {
						const path = field.id.split('.');
						path.shift();
						set(path, obj, field.value);
					}
					return obj;
				}));
			} else {
				for (const field of section.fields) set(field.id, values, field.value);
			}
		}
		return values;
	}

	public set errors(errors: {[index: string]: any}) {
		this.isValid = !errors;
		for (const field of this.fields) {
			if (field instanceof ValidatingField) {
				const result = getProperty(errors, field.id);
				field.flag(result && result.errorMessage);
			}
		}
		this.showErrors();
	}
}

export abstract class ApiFormConfig<T = void> extends FormConfig {
	protected readonly service?: string;

	protected abstract onSuccess?(response: ApiResponse, data?: T): void;

	protected apiRequest(params?: {[index: string]: any}, auth?: ApiCredentials): Promise<ApiResponse> {
		return Api[this.method as any](this.service || this.id, params, auth);
	}

	protected sendRequest(data?: T): Promise<ApiResponse> {
		return this.apiRequest(this.values);
	}

	public submit(data?: T): void {
		if (this.isProcessing) return;
		if (this.validate()) {
			this.isProcessing = true;
			this.sendRequest(data).then((response) => {
				this.onSuccess(response, data);
			}, (response) => {
				this.errorMessage = response.error;
				response.data ? this.errors = response.data : this.showErrors(true);
			}).finally(() => {
				this.isProcessing = false;
			});
		}
	}
}
