import {FormSection, FormMultiSection} from 'elements/form';
import {viewResources, PLATFORM, bindable, autoinject} from 'aurelia-framework';

@autoinject
@viewResources(
	PLATFORM.moduleName('elements/form/fieldcontainer'),
	PLATFORM.moduleName('elements/form/choice'),
	PLATFORM.moduleName('elements/button/iconbutton')
)
export class SubSection {
	@bindable private readonly section: FormMultiSection;
	@bindable private readonly sub: FormSection;
	@bindable private readonly index: number;

	constructor(private readonly element: Element) {}

	private attached(): void {
		if (!this.sub.isExistingSub) setTimeout(() => {
			window.scrollTo({
				top: this.element.getBoundingClientRect().top + window.pageYOffset - this.section.form.viewModel.app.header.height
			});
		}, 0);
	}
}
