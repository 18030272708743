import {autoinject, bindable, viewResources, PLATFORM} from 'aurelia-framework';
import {MDCDialog} from '@material/dialog';
import {DialogContainer, ActiveDialog} from './container';
import {App} from 'app';
import {AppConfig} from 'appconfig';
import {DialogButton} from './button';

@autoinject
@viewResources(PLATFORM.moduleName('elements/button'))
export abstract class Dialog<T = any> {
	@bindable protected element: Element;
	protected abstract readonly heading: string;
	protected readonly icon: string;
	protected readonly buttons: DialogButton[];
	protected readonly config: AppConfig;
	protected container: DialogContainer;
	protected data: T;
	protected cssClass: string = '';
	protected onClosed: () => void;
	protected onOpened: () => void;
	private mdcComponent: MDCDialog;
	private isHidden: boolean = false;

	constructor(protected app: App) {
		this.config = app.config;
	}

	public open(): void {
		this.mdcComponent.open();
		this.isHidden = false;
	}

	public hide(): void {
		this.isHidden = true;
	}

	public close(): void {
		this.container.close();
	}

	public closeAll(): void {
		this.container.close(true);
	}

	protected attached(): void {
		this.onClosed = () => {
			this.container.close(true);
		};
		this.mdcComponent = new MDCDialog(this.element);
		if (this.onOpened) this.mdcComponent.listen('MDCDialog:opened', this.onOpened);
		this.mdcComponent.listen('MDCDialog:closed', this.onClosed);
		this.open();
	}

	protected detached(): void {
		if (this.onOpened) this.mdcComponent.unlisten('MDCDialog:opened', this.onOpened);
		this.mdcComponent.unlisten('MDCDialog:closed', this.onClosed);
		this.mdcComponent.destroy();
	}

	private activate(activeDialog: ActiveDialog) {
		activeDialog.dialog = this;
		this.container = activeDialog.container;
		this.data = activeDialog.data;
	}
}
