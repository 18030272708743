import {OrganizationData} from 'modelData';
import {ApiFormConfig} from 'elements/form';
import {InputField} from 'elements/form/input';
import {CancelButton, OkButton} from 'elements/dialog/button';
import {Dialog} from 'elements/dialog';
import {ApiResponse} from 'api';
import {ComboField} from 'elements/form/select';

class EditOrganizationForm extends ApiFormConfig<EditOrganization> {
	protected readonly method = 'put';

	protected onSuccess(response: ApiResponse<OrganizationData>, dialog: EditOrganization): void {
		this.viewModel.app.config.user.organization = response.data;
		this.viewModel.app.snackbarContainer.open('Account has been updated');
		dialog.closeAll();
	}
}

export class EditOrganization extends Dialog<string[]> {
	protected readonly heading: string = 'Edit Account Information';
	protected readonly icon: string = 'person';
	protected cssClass: string = 'wide';
	private form: EditOrganizationForm;

	protected buttons = [
		new CancelButton(() => {
			this.close();
		}),
		new OkButton(() => {
			this.form.submit(this);
		}, 'Save')
	];

	protected attached(): void {
		this.form = new EditOrganizationForm(
			'organization',
			new InputField('name', 'Company Name', 'business', true),
			new InputField('url', 'Website', 'link'),
			new InputField('address.streetAddress', 'Address', 'person_pin'),
			new InputField('address.extendedAddress', 'Address (line 2)'),
			new InputField('address.city', 'City'),
			new InputField('address.region', 'State/Province/Region'),
			new InputField('address.postalCode', 'Postal Code'),
			new ComboField('address.country', 'Country', this.data, 'flag', true)
		);
		this.form.values = this.app.config.user.organization;
		super.attached();
	}
}
