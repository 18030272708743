import {ApiFormConfig} from 'elements/form';
import {CreatePasswordField, ConfirmPasswordField} from 'elements/form/input';
import {CancelButton, OkButton} from 'elements/dialog/button';
import {Dialog} from 'elements/dialog';
import {ApiResponse} from 'api';

class ChangePasswordForm extends ApiFormConfig<ChangePassword> {
	protected readonly service = 'user/self/resetpassword';
	protected readonly method = 'put';

	protected onSuccess(response: ApiResponse, dialog: ChangePassword): void {
		this.viewModel.app.snackbarContainer.open('Pasword has been updated');
		dialog.closeAll();
	}
}

export class ChangePassword extends Dialog {
	protected readonly heading: string = 'Change Password';
	protected readonly icon: string = 'lock';
	private isDone: boolean = false;

	private readonly form: ChangePasswordForm = new ChangePasswordForm(
		'change-password',
		new CreatePasswordField(true),
		new ConfirmPasswordField(true)
	);

	protected buttons = [
		new CancelButton(() => {
			this.close();
		}),
		new OkButton(() => {
			this.form.submit(this);
		}, 'Save')
	];
}
