import {RouteConfig} from 'aurelia-router';

export class Route {
	public readonly isPublic: boolean = false;
	public readonly isPublicOnly: boolean = false;
	public readonly name: string;
	public readonly route: string;

	constructor(
		public readonly module: string,
		public readonly title: string,
		public readonly icon: string,
		public readonly isVisible: boolean,
		appendRoute?: string,
		public readonly baseRoute?: string,
		public readonly hasDrawer: boolean = false,
		public readonly hasFooter: boolean = false
	) {
		this.name = this.module.replace(/^.*?\/(\w+)(\/index\.html)?$/, '$1');
		if (this.baseRoute === undefined) this.baseRoute = this.name;
		this.route = this.baseRoute;
		if (appendRoute) this.route += appendRoute;
	}

	public get config(): RouteConfig {
		return {
			route: this.route,
			href: this.baseRoute,
			name: this.name,
			moduleId: this.module,
			nav: this.isVisible,
			title: this.title,
			settings: {route: this}
		};
	}
}

export class PublicRoute extends Route {
	public readonly isPublic: boolean = true;
}

export class PublicOnlyRoute extends PublicRoute {
	public readonly isPublicOnly: boolean = true;
}
