import {bindable, viewResources, PLATFORM, autoinject} from 'aurelia-framework';
import {UserData} from 'modelData';
import {App} from 'app';
import {Api} from 'api';
import {formatDate} from 'utils';

import './profile.scss';

@autoinject
@viewResources(PLATFORM.moduleName('elements/button'))
export class Profile {
	@bindable private readonly user: UserData;
	@bindable private readonly config: ProfileConfig;
	private registeredAt: string;
	private referredBy: string;
	private lastLoginAt: string;
	private ndaStatus: string;
	private cardCssClass: string;

	constructor(private readonly app: App) {}

	private attached(): void {
		if (this.user._createdAt) this.registeredAt = formatDate(this.user._createdAt);
		if (this.user.lastLoginAt && this.user !== this.app.config.user) this.lastLoginAt = formatDate(this.user.lastLoginAt);
		if (this.config.showNda) this.ndaStatus = this.user.agreedToNda ? 'Yes' : 'No';
		if (this.user.invitation) {
			const {name} = this.user.invitation.representative;
			this.referredBy = `${name.first} ${name.last}`;
		}
		this.cardCssClass = this.config.showCompany && this.config.showAssignment ? '' : 'mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet';
	}

	private changePassword(): void {
		this.app.dialogContainer.open(PLATFORM.moduleName('dialogs/changepassword'));
	}

	private editUser(): void {
		this.app.dialogContainer.open(PLATFORM.moduleName('dialogs/edituser'));
	}

	private editOrganization(): void {
		this.app.isLoading = true;
		Api.get<string[]>('countries').then((response) => {
			this.app.isLoading = false;
			this.app.dialogContainer.open(PLATFORM.moduleName('dialogs/editorganization'), response.data);
		}, (response) => {
			this.app.isLoading = false;
			this.app.snackbarContainer.open(response.error);
		});
	}
}

export class ProfileConfig {
	constructor(
		public readonly showCompany: boolean = true,
		public readonly isEditable: boolean = true,
		public readonly createLinks: boolean = false,
		public readonly showNda: boolean = false,
		public readonly showAssignment: boolean = false
	) {}
}
