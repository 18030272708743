import {bindable, autoinject} from 'aurelia-framework';

import './video.scss';

@autoinject()
export class VideoPlayer {
	@bindable private video: string;
	private src: string;
	private element: HTMLElement;
	private playerElement: HTMLDivElement;
	private videoElement: HTMLVideoElement;
	private css: {'background-image': string};

	constructor(element: Element) {
		this.element = element as HTMLElement;
	}

	private attached(): void {
		this.src = `/static/videos/${this.video}.mp4`;
		this.css = {'background-image': `url(/static/videos/${this.video}.jpg)`};
	}

	private play(): void {
		this.element.classList.add('playing');
		this.videoElement.play();
	}

	private hide(): void {
		if (!this.videoElement.paused) this.videoElement.pause();
		this.element.classList.remove('playing');
	}
}
