import {noView} from 'aurelia-framework';
import {Api} from 'api';
import {ViewModel} from 'viewModel';

@noView
export class SignOut extends ViewModel {
	private activate(): void {
		Api.delete('session').then(() => {
			this.app.router.navigate('');
			location.reload();
		});
	}
}
