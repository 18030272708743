import {bindable} from 'aurelia-framework';

import './button.scss';

export class FloatingButton {
	@bindable private readonly icon: string;
	@bindable private readonly label: string;
	@bindable private readonly type: string = 'button';
	private css: string;
}
