import {PLATFORM} from 'aurelia-framework';
import {MDCTextField, MDCTextFieldHelperText} from '@material/textfield';
import {ValidatingField} from './field';

import './input.scss';

export class InputField extends ValidatingField<MDCTextField> {
	protected readonly view = PLATFORM.moduleName('elements/form/input.html');
	protected readonly hasOutline = true;
	public readonly MDCComponent = MDCTextField;
	public containerCssClasses = ['mdc-text-field', 'mdc-text-field--outlined'];
	public value = '';
	public type = 'text';
	public placeholder: string = '';

	public attached(): void {
		this.container.mdcComponent.useNativeValidation = false;
		this.helper.setMdcComponent(MDCTextFieldHelperText);
	}

	public valueChanged(): void {
		if (this.container && this.container.mdcComponent) this.container.mdcComponent.value = this.value || '';
	}
}

export class TextareaField extends InputField {
	public value: string = '';
	protected readonly view = PLATFORM.moduleName('elements/form/textarea.html');
	public autoSize: boolean = true;
	public autoSizeAppend: string = '\n';
	private shadowContainer: HTMLDivElement;
	private shadow: HTMLTextAreaElement;

	constructor(
		id: string,
		label?: string,
		isRequired?: boolean,
		public rows: number = 1
	) {
		super(id, label, undefined, isRequired);
		this.containerCssClasses[1] = 'mdc-text-field--textarea';
	}

	public attached(): void {
		super.attached();
		if (this.autoSize) {
			this.shadow = document.createElement('textarea');
			this.shadow.disabled = true;
			this.shadow.className = 'mdc-text-field__input';
			this.shadowContainer = document.createElement('div');
			this.shadowContainer.className = 'autosize-shadow';
			this.shadowContainer.append(this.shadow);
			this.container.element.prepend(this.shadowContainer);
			this.container.fieldElement.style.resize = 'none';
			this.valueChanged();
		}
	}

	public resize(): void {
		if (!this.shadow) return;
		this.shadow.scrollTop = 999999;
		this.container.fieldElement.style.height = (this.shadow.clientHeight + this.shadow.scrollTop) + 'px';
	}

	public valueChanged(): void {
		if (!this.shadow) return;
		this.shadow.value = this.value + this.autoSizeAppend;
		this.resize();
		super.valueChanged();
	}
}

export class EmailField extends InputField {
	public type = 'email';

	constructor(isRequired: boolean = false, id: string = 'email', label: string = 'Email', icon: string = 'email') {
		super(id, label, icon, isRequired);
	}
	
	public validate(): boolean {
		if (!super.validate()) return false;
		this.flag(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.value) ? null : 'Invalid email address');
		return this.isValid;
	}
}

export class PhoneField extends InputField {
	public type = 'tel';
	public placeholder = '+00 999-999-9999, for example';

	constructor(isRequired: boolean = false, id: string = 'phone', label: string = 'Phone', icon: string = 'phone') {
		super(id, label, icon, isRequired);
		this.helper.text = 'Please include country code';
	}
}

export class PasswordField extends InputField {
	public type = 'password';

	constructor(isRequired: boolean = false, id: string = 'password', label: string = 'Password', icon: string = 'lock') {
		super(id, label, icon, isRequired);
	}
}

export class CreatePasswordField extends PasswordField {
	public placeholder = 'Minimum 8 characters';
	
	constructor(isRequired: boolean = false, id: string = 'password', label: string = 'Create Password', icon: string = 'lock') {
		super(isRequired, id, label, icon);
	}

	public validate(): boolean {
		if (!super.validate()) return false;
		if (this.value.length < 8) this.flag('Must be at least 8 characters');
		return this.isValid;
	}
}

export class ConfirmPasswordField extends PasswordField {
	constructor(isRequired: boolean = false, private readonly linkedFieldId: string = 'password', id: string = 'confirmPassword', label: string = 'Confirm Password', icon: string = 'lock') {
		super(isRequired, id, label, icon);
	}

	public validate(): boolean {
		if (!super.validate()) return false;
		const linkedField = Array.from(this.form.fields).find((field) => field.id === this.linkedFieldId);
		if (linkedField.value !== this.value) this.flag(`Does not match ${linkedField.label} field`);
		return this.isValid;
	}
}
