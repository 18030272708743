import {Dialog} from '.';

abstract class Button {
	public abstract icon: string;
	public abstract label: string;
	public abstract action: (dialog: Dialog) => void;
	public isDefault: boolean = false;
	public cssClass: string = '';
}


export class DialogButton extends Button {
	constructor(
		public icon: string,
		public label: string,
		public action: (dialog: Dialog) => void,
		public cssClass: string = ''
	) {
		super();
	}
}

export class OkButton extends Button {
	public icon: string = 'check_circle';
	public isDefault: boolean = true;

	constructor(
		public action: (dialog: Dialog) => void = (dialog): void => {
			dialog.close();
		},
		public label: string = 'OK'
	) {
		super();
	}
}

export class YesButton extends OkButton {
	public label: string = 'Yes';
}

export class CancelButton extends Button {
	public icon: string = 'cancel';
	public label: string = 'Cancel';

	constructor(
		public action: (dialog: Dialog) => void = (dialog): void => {
			dialog.close();
		}
	) {
		super();
	}
}

export class NoButton extends CancelButton {
	public label: string = 'No';
}
