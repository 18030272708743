import {viewResources, PLATFORM} from 'aurelia-framework';
import {ApiFormConfig} from 'elements/form';
import {ViewModel} from 'viewModel';
import {InputField, EmailField, PhoneField, TextareaField} from 'elements/form/input';
import {App} from 'app';

class ContactForm extends ApiFormConfig {
	public readonly submitButton = 'Submit';
	public app: App;

	protected onSuccess(): void {
		this.reset();
		this.viewModel.app.snackbarContainer.open('Your message has been sent - Thank you!');
	}

	public reset(): void {
		super.reset();
		const {user} = this.app.config;
		if (user) this.values = {
			name: `${user.name.first} ${user.name.last}`,
			email: user.email,
			phone: user.phone
		};
	}
}

@viewResources(PLATFORM.moduleName('elements/form'))
export class Contact extends ViewModel {
	private readonly form: ContactForm;

	constructor(app: App) {
		super(app);

		let message: TextareaField;
		this.form = new ContactForm(
			'contact',
			new InputField('name', 'Name', 'person', true),
			new EmailField(true),
			new PhoneField(),
			message = new TextareaField('message', 'Message', true)
		);
		message.gridCellCssClass = 'mdc-layout-grid__cell--span-12';
		this.form.app = app;
		this.form.reset();
	}
}
