import {PLATFORM} from 'aurelia-pal';
import {AppConfig} from 'appconfig';
import {Route, PublicRoute, PublicOnlyRoute} from 'route';

export class ClientAppConfig extends AppConfig {
	public readonly perspective = 'client';
	public readonly routes = [
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/home'), '', null, false, null, '', false, true),
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/contact'), 'Contact', 'mail_outline', true),
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/resetpassword'), 'Reset Password', null, false, '/:key'),
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/privacy/index.html', 'privacy'), 'Privacy Notice', null, false, null, 'privacy', false, true),
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/terms/index.html', 'terms'), 'Terms of Service', null, false, null, 'terms', false, true),
		new PublicRoute(PLATFORM.moduleName('perspectives/client/views/nda/index.html', 'nda'), 'Mutual Non-Disclosure Agreement', null, false, null, 'nda', false, true),
		new PublicRoute(PLATFORM.moduleName('views/404/index.html'), '404 - Page not found', null, false, null, '404'),
		new PublicOnlyRoute(PLATFORM.moduleName('perspectives/client/views/invitation'), 'Register', 'how_to_reg', true, null, 'register', false, true),
		new PublicOnlyRoute(PLATFORM.moduleName('perspectives/client/views/register'), 'Register', null, false, '/:invitationCode'),
		new Route(PLATFORM.moduleName('perspectives/client/views/documents', 'perspective.client'), 'Documents', 'description', true),
		new Route(PLATFORM.moduleName('perspectives/client/views/document', 'perspective.client'), 'Document', null, false, '/:documentId/:sectionId?', 'documents', true),
		new Route(PLATFORM.moduleName('views/review', 'review'), 'Review Document', null, false, '/:documentId/:sectionId?', 'review', true),
		new Route(PLATFORM.moduleName('views/account'), 'My Account', 'account_circle', true),
		new Route(PLATFORM.moduleName('views/signout'), 'Sign Out', 'exit_to_app', true)
	];
}
