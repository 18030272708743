import {bindable, autoinject, PLATFORM} from 'aurelia-framework';
import MDCComponent from '@material/base/component';
import {MDCFloatingLabel} from '@material/floating-label';
import {Field} from './field';

@autoinject
export class FieldContainer<T extends MDCComponent<any>> {
	@bindable public readonly element: HTMLDivElement;
	@bindable public readonly labelElement: HTMLLabelElement;
	@bindable public readonly helperTextElement: HTMLDivElement;
	public field: Field<T>;
	public fieldElement: HTMLElement;
	public mdcComponent: T;
	private viewModel: string = PLATFORM.moduleName('elements/form/fieldmodel');
	private labelMdcComponent: MDCFloatingLabel;
	private css: string;

	private detached(): void {
		if (this.mdcComponent) this.mdcComponent.destroy();
		if (this.labelMdcComponent) this.labelMdcComponent.destroy();
		if (this.field.detached) this.field.detached();
	}

	public fieldViewAttached(fieldElement: HTMLElement): void {
		this.fieldElement = fieldElement;
		this.mdcComponent = new this.field.MDCComponent(this.element);
		if (this.field.label && !this.field.hasOutsideLabel) this.labelMdcComponent = new MDCFloatingLabel(this.labelElement);
	}

	private activate(field: Field<T>): void {
		this.field = field;
		this.css = this.field.containerCssClasses.join(' ') + (this.field.icon ? ` ${this.field.containerCssClasses[0]}--with-leading-icon` : '');
		this.field.setContainer(this);
	}
}
