import {UserData} from 'modelData';
import {ApiFormConfig} from 'elements/form';
import {PhoneField, InputField, EmailField} from 'elements/form/input';
import {CancelButton, OkButton} from 'elements/dialog/button';
import {Dialog} from 'elements/dialog';
import {ApiResponse} from 'api';
import {App} from 'app';

class EditUserForm extends ApiFormConfig<EditUser> {
	protected readonly method = 'put';

	protected onSuccess(response: ApiResponse<UserData>, dialog: EditUser): void {
		this.viewModel.app.updateAuth(response.data);
		this.viewModel.app.snackbarContainer.open('Account has been updated');
		dialog.closeAll();
	}
}

export class EditUser extends Dialog {
	protected readonly heading: string = 'Edit Account Information';
	protected readonly icon: string = 'person';
	private readonly form: EditUserForm;

	protected buttons = [
		new CancelButton(() => {
			this.close();
		}),
		new OkButton(() => {
			this.form.submit(this);
		}, 'Save')
	];

	constructor(app: App) {
		super(app);
		this.form = new EditUserForm(
			'user',
			new InputField('name.first', 'First Name', 'person', true),
			new InputField('name.last', 'Last Name', 'person', true),
			new PhoneField(true),
			new EmailField(true)
		);
		this.form.values = this.app.config.user;
	}
}
