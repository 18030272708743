import {bindable} from 'aurelia-framework';
import MDCComponent from '@material/base/component';
import {Field} from 'elements/form/field';

export class FieldModel<T extends MDCComponent<any>> {
	@bindable private element: HTMLElement;
	private field: Field<T>;

	private activate(field: Field<T>): void {
		this.field = field;
	}

	private attached(): void {
		if (this.field.container) this.field.container.fieldViewAttached(this.element);
		if (this.field.attached) this.field.attached();
	}
}
