import {viewResources, PLATFORM} from 'aurelia-framework';
import {ApiFormConfig, FormSection} from 'elements/form';
import {InputField, EmailField, PhoneField, ConfirmPasswordField, CreatePasswordField} from 'elements/form/input';
import {CheckboxField} from 'elements/form/choice';
import {ComboField} from 'elements/form/select';
import {ApiResponse, Api} from 'api';
import {ViewModel} from 'viewModel';
import {InvitationData, UserData} from 'modelData';

import './register.scss';

class RegisterForm extends ApiFormConfig {
	public readonly submitButton = 'Register';
	public invitationCode: string;
	public checkTerms: () => boolean;

	public validate(): boolean {
		const wasValid = super.validate();
		if (!this.checkTerms()) this.isValid = false;
		if (wasValid) this.showErrors();
		return this.isValid;
	}

	protected sendRequest(): Promise<ApiResponse> {
		return this.apiRequest(Object.assign(this.values, {invitationCode: this.invitationCode}));
	}

	protected onSuccess(response: ApiResponse): void {
		const {app} = this.viewModel;
		const user: UserData = response.data;

		app.updateAuth(user);
		app.cookieNotice.accept();
		if (user.documents.length === 1) {
			app.router.navigateToRoute('document', {documentId: user.documents[0]});
		} else {
			app.router.navigateToRoute('documents');
		}
	}
}

@viewResources(
	PLATFORM.moduleName('elements/form'),
	PLATFORM.moduleName('elements/button/floatingbutton')
)
export class Register extends ViewModel {
	private form: RegisterForm;
	private invitation: InvitationData;

	protected attached(): void {
		this.app.load.then(() => {
			const {invitationCode} = this.app.router.currentInstruction.params;
			if (!invitationCode) this.redirect(true);

			Promise.all([
				Api.get<InvitationData>('invitation/' + invitationCode),
				Api.get<string[]>('countries')
			]).then(([invitationResult, countriesResult]) => {
				if (!invitationResult || !invitationResult.data || !invitationResult.data.isAvailable) return this.redirect();
				super.attached();
				this.invitation = invitationResult.data;
				let terms: CheckboxField;
				let agreements: FormSection;
				this.form = new RegisterForm(
					'user',
					new FormSection(
						'Account Details',
						null,
						new InputField('name.first', 'First Name', 'person', true),
						new InputField('name.last', 'Last Name', 'person', true),
						new PhoneField(true),
						new EmailField(true),
						new CreatePasswordField(true),
						new ConfirmPasswordField(true)
					),
					new FormSection(
						'Company Information',
						null,
						new InputField('organization.name', 'Company Name', 'business', true),
						new ComboField('organization.address.country', 'Country', countriesResult.data, 'flag', true),
						new InputField('organization.url', 'Website', 'link'),
						new InputField('organization.address.streetAddress', 'Address', 'person_pin'),
						new InputField('organization.address.extendedAddress', 'Address (line 2)'),
						new InputField('organization.address.city', 'City'),
						new InputField('organization.address.region', 'State/Province/Region'),
						new InputField('organization.address.postalCode', 'Postal Code')
					),
					agreements = new FormSection(
						'Agreements',
						null,
						terms = new CheckboxField('agreedToTerms', 'I agree to the <a href="/terms" target="_blank">CVA Terms of Service</a>.'),
						new CheckboxField('agreedToNda', '(Optional) I agree to the <a href="/nda" target="_blank">Mutual Non-Disclosure Agreement</a>.')
					)
				);
				this.form.invitationCode = this.invitation.code;
				this.form.checkTerms = () => {
					agreements.errorMessage = terms.value ? null : 'You must agree to the Terms of Service before you can register.';
					return terms.value;
				};
			}, () => {
				this.redirect();
			});
		});
	}

	private redirect(skipMessage?: boolean): void {
		this.app.router.navigateToRoute('invitation');
		if (!skipMessage) this.app.snackbarContainer.open('Invalid invitation code');
	}
}
