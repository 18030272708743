import {ApiFormConfig} from 'elements/form';
import {EmailField, PasswordField} from 'elements/form/input';
import {CancelButton, OkButton} from 'elements/dialog/button';
import {Dialog} from 'elements/dialog';
import {ApiResponse, Api} from 'api';
import {UserData} from 'modelData';
import {SignIn} from 'dialogs/signin';
import {App} from 'app';

class ForgotPasswordForm extends ApiFormConfig<Dialog> {
	public readonly service = 'user';
	public readonly method = 'get';

	protected apiRequest(params?: {[index: string]: any}): Promise<ApiResponse> {
		return Api[this.method](`${this.service}/${params.email}/forgotpassword`);
	}

	public onSuccess(response: ApiResponse, dialog: ForgotPassword): void {
		dialog.done();
	}
}

export class ForgotPassword extends Dialog {
	protected readonly heading: string = 'Reset Password';
	protected readonly icon: string = 'lock';
	private emailField: EmailField = new EmailField(true);
	private isDone: boolean = false;

	private readonly form: ForgotPasswordForm = new ForgotPasswordForm(
		'forgot-password',
		this.emailField
	);

	protected buttons = [
		new CancelButton(() => {
			this.close();
		}),
		new OkButton(() => {
			this.form.submit(this);
		}, 'Submit')
	];

	protected attached(): void {
		super.attached();
		const last = this.container.last(false);
		const lastDlg = last && last.dialog as SignIn;
		if (lastDlg && lastDlg.emailField) this.emailField.value = lastDlg.emailField.value;
	}

	public done(): void {
		this.isDone = true;
		this.buttons = [
			new OkButton(() => {
				this.closeAll();
			})
		];
	}
}
