import {App} from 'app';
import {Snackbar} from 'elements/snackbar/snackbar';

export class CookieNotice {
	private expireDays: number = 30;
	private message: string = 'This site uses cookies to allow us to maintain a session when you sign in and use your account. ' +
	                          'We transmit session cookies through an encrypted connection, and they do not contain any personal information. ' +
							  'By registering, signing in, or dismissing this notice, you are consenting to this use of cookies. ' +
							  'For more on this topic, see our <a href="/privacy">Privacy Policy</a>.'

	private expireSeconds: number = this.expireDays * 24 /* hours */ * 60 /* minutes */ * 60 /* seconds */;
	private snackbar: Snackbar;

	constructor(private readonly app: App) {}

	private setCookie(): void {
		document.cookie = `cqAllowCookies=true;max-age=${this.expireSeconds}`;
	}

	public show(): void {
		if (!this.isAccepted && !this.snackbar) this.snackbar = this.app.snackbarContainer.open(this.message, -1, 'OK', () => this.setCookie(), true);
	}

	public accept(): void {
		if (this.snackbar) this.snackbar.remove();
		this.setCookie();
	}

	public get isAccepted(): boolean {
		return !!document.cookie.split(/\s*;\s*/).find((cookie) => {
			const [name, value] = cookie.split('=').map((data) => data && decodeURIComponent(data));
			return name === 'cqAllowCookies' && value === 'true';
		});
	}
}
