import {viewResources, PLATFORM} from 'aurelia-framework';
import {FormConfig} from 'elements/form';
import {InputField} from 'elements/form/input';
import {ViewModel} from 'viewModel';
import {App} from 'app';

import './invitation.scss';

class InvitationForm extends FormConfig {
	public readonly submitButton = 'Continue';
	
	public submit(): void {
		const {invitationCode} = this.values;
		if (invitationCode) {
			this.viewModel.app.router.navigateToRoute('register', {invitationCode});
		} else {
			this.viewModel.app.snackbarContainer.open('Please enter an invitation code');
		}
	}
}

@viewResources(PLATFORM.moduleName('elements/form'))
export class Invitation extends ViewModel {
	private readonly form: InvitationForm;

	constructor(app: App) {
		super(app);
		const field = new InputField('invitationCode');
		field.placeholder = 'Invitation Code';
		this.form = new InvitationForm(
			'invitation',
			field
		);
	}
}
